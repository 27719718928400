@font-face {
  font-family: 'Raleway-italic';
  src: local('Raleway-italic'), url(./assets/fonts/Raleway-Italic-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-italic'), url(./assets/fonts/Raleway-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville-bold';
  src: local('LibreBaskerville-bold'), url(./assets/fonts/LibreBaskerville-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville-italic';
  src: local('LibreBaskerville-italic'), url(./assets/fonts/LibreBaskerville-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville';
  src: local('LibreBaskerville'), url(./assets/fonts/LibreBaskerville-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Mulish-italic';
  src: local('Mulish-italic'), url(./assets/fonts/Mulish-Italic-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Mulish';
  src: local('Mulish'), url(./assets/fonts/Mulish-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'EastSeaDokdo';
  src: local('EastSeaDokdo'), url(./assets/fonts/EastSeaDokdo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Chilanka';
  src: local('Chilanka'), url(./assets/fonts/Chilanka-Regular.ttf) format('truetype');
}

html {

  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Helvetica';
  overflow-x: hidden;
}



main {
  width: 100%;
  // min-height: 100svh;
  max-width: 100dvw;
  background-image: url('./assets/img/background.png');
  background-size: auto;
  background-repeat: repeat-y;
  background-position: center top;

}

.flex_col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}